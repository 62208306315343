// Get the modal
var modal = document.getElementById("myModal");

// Get the button that opens the modal
var btn = document.getElementById("myBtn");
var btn2 = document.getElementById("myBtn2");
var anchor = document.getElementById("anchor-link");

// Get the <span> element that closes the modal
var span = document.getElementsByClassName("close")[0];

// When the user clicks the button, open the modal 
btn.onclick = function() {
  modal.style.display = "flex";
}

btn2.onclick = function() {
  modal.style.display = "flex";
}

// When the user clicks on <span> (x), close the modal
span.onclick = function() {
  modal.style.display = "none";
}

// Was used to remove the modal when clicking on anchor link (that was gonna take the user from the modal to the website)
// This anchor has been removed from the modal so we dont need this anymore (it was preventing the modal to close properly otherwise)
// anchor.onclick = function() {
//    setTimeout(function () {
//       modal.style.display = "none";      
//     }, 500);  
// }

//FOODTRUCK MODAL

// Get the modal
var modal_foodtruck = document.getElementById("foodtruck-modal");

// Get the button that opens the modal
var btn_modal = document.getElementById("btn-modal");

// When the user clicks the button, open the modal 
btn_modal.onclick = function() {
  modal_foodtruck.style.display = "flex";
}

// Get the <span> element that closes the modal
var span_foodtruck = document.getElementsByClassName("close")[1];

// When the user clicks on <span> (x), close the modal
span_foodtruck.onclick = function() {
  modal_foodtruck.style.display = "none";
}

// When the user clicks anywhere outside of the modal, close it
window.onclick = function(event) {
  if (event.target == modal) {
    modal.style.display = "none";
  }
  
  if (event.target == modal_foodtruck) {
    modal_foodtruck.style.display = "none";
  }
}